import React from 'react';
import { Helmet } from 'react-helmet';

export default React.memo(
  ({
    author,
    siteUrl,
    datePublished,
    description,
    isBlogPost,
    organization,
    title,
    url
  }) => {
    const baseSchema = [
      {
        '@context': 'http://schema.org',
        '@type': 'Organization',
        url: siteUrl,
        name: organization.name,
        description: description,
        foundingDate: organization.foundingDate,
        foundingLocation: organization.foundingLocation
      },
    ];

    const schema = isBlogPost
      ? [
          ...baseSchema,
          {
            '@context': 'http://schema.org',
            '@type': 'BlogPost',
            url,
            name: title,
            headline: title,
            description,
            datePublished,
            author: {
              '@type': 'Person',
              name: author,
            },
            publisher: {
              '@type': 'Organization',
              url: organization.url,
              name: organization.name,
            },
            mainEntityOfPage: {
              '@type': 'WebSite',
              '@id': siteUrl,
            }
          },
        ]
      : baseSchema;

    return (
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(schema)}</script>
      </Helmet>
    );
  },
);
import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

const Header = ({ siteBrandName }) => (
  <header className="w-full container mx-auto p-6">
    <div className="flex flex-col md:flex-row md:items-center">
      <div className="flex md:w-3/6 p-5 justify-center md:justify-start text-site-blue-dark">
        <h1 className="logo mb-0">
          <Link to="/">
            {siteBrandName}
          </Link>
        </h1>
      </div>
      <div className="flex justify-center md:w-3/6 md:justify-end md:mr-20">
        <Link to="/blog" className="text-gray-700 md:p-4 p-2" title="Read Ruby and Rails Blogs" alt="Ruby and Rails Blogs by scriptday">Blog</Link>
        <a href="mailto:info@scriptday.com" className="text-gray-700 md:p-4 p-2" title="Email scriptday" alt="Contact scriptday via Email">Let's Talk</a>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteBrandName: PropTypes.string,
}

Header.defaultProps = {
  siteBrandName: ``,
}

export default Header;

import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

import SchemaOrg from './SchemaOrg';

import socialBanner from '../images/social_banner.png';


function SEO({ url, description, lang, meta, keywords, title, isBlogPost, datePublished }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            organization {
              name
              foundingDate
              foundingLocation
            }
            blog {
              title
            }
            social {
              twitter
            }
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description;
  const ogSiteName = isBlogPost ? [site.siteMetadata.title, site.siteMetadata.organization.name].join(" | ") : title
  const ogTitle = isBlogPost ? [title, ogSiteName].join(" | ") : ogSiteName
  const ogType = isBlogPost ? "article" : "website"
  const absoluteUrl = isBlogPost ? 
        `${site.siteMetadata.siteUrl}${url}` : 
        (url ? `${site.siteMetadata.siteUrl}${url}` : site.siteMetadata.siteUrl)

  const socialBannerUrl = `${site.siteMetadata.siteUrl}${socialBanner}`

  return (
    <React.Fragment>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={title}
        titleTemplate={`%s`}
        meta={[
          {
            name: `description`,
            content: metaDescription,
          },
          {
            property: `og:title`,
            content: ogTitle,
          },
          {
            property: `og:description`,
            content: title,
          },
          {
            property: `og:type`,
            content: ogType,
          },
          {
            property: `og:site_name`,
            content: site.siteMetadata.title,
          },
          {
            property: `og:url`,
            content: absoluteUrl,
          },
          {
            property: `og:image`,
            content: socialBannerUrl,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: site.siteMetadata.social.twitter,
          },
          {
            name: `twitter:title`,
            content: ogTitle,
          },
          {
            name: `twitter:description`,
            content: description,
          },
        ]
              .concat(
                keywords && keywords.length > 0
                  ? {
                    name: `keywords`,
                    content: keywords.join(`, `)
                  }
                : []
              )
              .concat(meta)}
      />
      <SchemaOrg
              isBlogPost={isBlogPost}
              url={absoluteUrl}
              title={title}
              description={site.siteMetadata.description}
              datePublished={datePublished}
              siteUrl={absoluteUrl}
              author={site.siteMetadata.author}
              organization={site.siteMetadata.organization}
      />
    </React.Fragment>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO;
